import vSelect from "vue-select";
import KTPortlet from "@/components/Portlet.vue";
import OutletSelect from "@/components/outlet/Select.vue";
import {
  getPaymentDetail,
  payInvoice,
  getAllDrafInvoiceOfOutlet,
  cancelOrder,
  removeGiftcardInvoice
} from "@/api/invoice";
import Loader from "@/common/loader";
import Swal from "sweetalert2";
import moment from "moment";
import { getReadyToPayGiftCard, releaseGiftCard } from "@/api/gift-card";
import axios from "@/common/api.js";
import SearchInput from "@/components/SearchInput.vue";
import _ from "lodash";

export default {
  components: {
    KTPortlet,
    OutletSelect,
    vSelect,
    SearchInput,
  },
  data() {
    return {
      promos: [],
      invoicePromo: [],
      giftCard: [],
      invoiceId: "",
      recompute: 0,
      cashValue: 0,
      cardValue: 0,
      invoiceData: null,
      giftCards: [],
      outletId: "",
      invoices: [],
      giftCardInvoice: {},
      listGiftCard: [],
      selectedGiftcard: [],
      listUser: [],
      selectedUser: "",
      selectedPaymentsType: "",
      listTypePay: [
        {
          value: 0,
          text: "Thẻ",
        },
        {
          value: 1,
          text: "Tiền mặt",
        },
        {
          value: 2,
          text: "Tặng",
        },
      ],
      selectedUserNull: false,
    };
  },
  created() {
    this.$store.dispatch("titleBreadcrumb", "Giỏ hàng / thanh toán");
  },
  mounted() {
    this.$root.$on("bv::modal::hidden", () => {
      this.invoicePromo = [];
    });
  },
  methods: {
    onSelectOutlet(evt) {
      this.outletId = evt;
      Loader.fire();

      return Promise.all([
        getAllDrafInvoiceOfOutlet(evt),
        getReadyToPayGiftCard(evt),
      ]).then(async (res) => {
        const [invoices, giftCards] = res;
        this.invoices = invoices.data;
        this.giftCards = giftCards.data;
        console.log(this.invoices);
        console.log("sdasda" + this.giftCards);
        Loader.close();
      });
    },

    payWithInvoiceId(_id) {
      Loader.fire();
      getPaymentDetail(_id)
        .then((res) => {
          this.invoiceId = _id;
          this.invoiceData = res.data;
          this.cardValue = this.invoiceData.total;
          this.$bvModal.show("payment-modal");
        })
        .finally(Loader.close);
    },

    payWithGiftCardId(giftCard) {
      this.giftCardInvoice = giftCard;
      this.$bvModal.show("payment-giftcard-modal");
      this.selectedUserNull = false;
      this.selectedUser = "";
      this.selectedPaymentsType = "";
      this.getUser();
    },

    releaseGiftCardOnCart() {
      Loader.fire();
      const { code, customer } = this.giftCardInvoice;
      let userInfo = {};

      if (this.selectedUser) {
        this.$bvModal.hide("payment-giftcard-modal");
        userInfo = {
          _id: this.selectedUser,
          name: this.listUser.find((u) => u._id === this.selectedUser).name,
        };
        releaseGiftCard({
          code,
          customer,
          userInfo,
          paymentMethod: this.selectedPaymentsType,
        })
          .then(async () => {
            const res = await getReadyToPayGiftCard(this.outletId);
            this.giftCards = res.data;
            Loader.close();
            Swal.fire({
              icon: "success",
              titleText: "Thanh toán thành công!",
              timer: 3000,
              confirmButtonText: "Đóng",
            });
          })
          .catch((err) => {
            if (
              err.errors === "Không thể thanh toán GiftCard với giá trị <= 0"
            ) {
              Loader.close();
              Swal.fire({
                icon: "warning",
                titleText: "Không thể thanh toán GiftCard với giá trị <= 0",
                timer: 3000,
                confirmButtonText: "Đóng",
              });
            }
          });
      } else {
        this.selectedUserNull = true;
        Loader.close();
      }
    },

    pay() {
      Loader.fire();
      const paymentData = {
        outletId: this.outletId,
        cash: this.cashValue,
        card: this.cardValue,
        promo: this.invoicePromo,
        giftCardIds: this.selectedGiftcard.map((item) => item.code),
        tips: this.invoiceData.sessions.map((session) => {
          return {
            sessionId: session._id,
            tipValue: session.tipValue || 0,
          };
        }),
      };

      payInvoice(this.invoiceId, paymentData)
        .then(() => {
          localStorage.removeItem("invoiceId");
          Loader.close();
          Swal.fire({
            icon: "success",
            titleText: "Thanh toán thành công!",
            timer: 3000,
            confirmButtonText: "Đóng",
          }).finally(() => {
            this.$bvModal.hide("payment-modal");
          });
        })
        .catch((e) => {
          console.error(e);
          Swal.fire({
            icon: "error",
            titleText: "Thanh toán không thành công!",
            text: "Vui lòng kiểm tra thông tin và thử lại",
          });
        })
        .finally(() => {
          getAllDrafInvoiceOfOutlet(this.outletId).then((res) => {
            this.invoices = res.data;
          });
          getReadyToPayGiftCard(this.outletId).then((res) => {
            this.giftCards = res.data;
          });
        });
    },

    cancelPayment() {
      Loader.fire();
      Swal.fire({
        icon: "warning",
        titleText: "Xác nhận huỷ đơn hàng này?",
        timer: 3000,
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Đóng",
        showCancelButton: true,
        reverseButtons: true,
      }).then((res) => {
        if (res.value) {
          cancelOrder(this.invoiceData._id)
            .then(() => {
              this.$bvModal.hide("payment-modal");
              return this.onSelectOutlet(this.outletId);
            })
            .finally(Loader.close);
        }
      });
    },

    calcTotal() {
      this.cashValue = 0;
      this.invoiceData.total =
        this.invoiceData.subTotal - this.promoTotal + this.tipTotal;
    },

    formatDate(timestamp) {
      return moment(timestamp).format("YYYY/MM/DD-HH:mm");
    },

    getDateToday() {
      const date = new Date();
      return moment(date).format("DD/MM/YYYY");
    },

    onSearch: _.debounce(function($event) {
      let uri = `/secure/r2/gift-card/giftcardSold?outletId=${this.outletId}${
        $event ? "&searchText=" + $event : ""
      }`;

      if ($event.length > 0) {
        axios.get(uri).then((res) => {
          this.listGiftCard = res.data.success;
        });
      } else {
        this.listGiftCard = [];
      }
    }, 300),

    getUser() {
      const params = {
        outlet: this.outletId,
      };
      this.$store.dispatch("updateListUsers");
      Loader.fire();
      this.$store
        .dispatch("getUsers", params)
        .then((res) => {
          this.listUser = res.usersList.filter(
            (itemUser) => itemUser.status === 1
          );
        })
        .finally(Loader.close);
    },

    isCollapsed(id) {
      return document.getElementById(id).classList.contains("show");
    },

    cancelGiftCardPaymentTable(giftCard) {
      this.giftCardInvoice = giftCard;
      this.cancelGiftCardPayment();
    },

    cancelGiftCardPayment() {
      Loader.fire();
      removeGiftcardInvoice(this.giftCardInvoice._id)
        .then(() => {
          Swal.fire({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "Huỷ thành công!",
            showConfirmButton: false,
            timer: 3000,
          });
          this.giftCards.splice(
            this.giftCards.indexOf(this.giftCardInvoice),
            1
          );
        })
        .catch((e) => {
          return Swal.fire({
            icon: "error",
            title: e.errors,
            showConfirmButton: false,
            timer: 3000,
          });
        })
        .finally(() => {
          Loader.close();
          this.$bvModal.hide("payment-giftcard-modal");
        });
    },
  },
  computed: {
    sessionProductFields() {
      return [
        { key: "name", label: "Dịch vụ", class: "product-name-card" },
        {
          key: "price",
          label: "Doanh thu",
          class: "modal-table-cell text-right white-space-nowrap title-revenue",
        },
      ];
    },

    sessionPromoFields() {
      return [
        { key: "name", label: "Giảm giá", class: "product-name-card" },
        {
          key: "value",
          label: "Giá trị",
          class: "modal-table-cell text-right white-space-nowrap",
        },
      ];
    },

    promoTotal() {
      this.recompute;
      return this.invoiceData.sessions.reduce(
        (total, session) => (total += parseInt(session.discountTotal)),
        0
      );
    },

    tipTotal() {
      return this.invoiceData.sessions.reduce((total, session) => {
        total += parseInt(session.tipValue) || 0;
        return total;
      }, 0);
    },

    giftCardfields() {
      return [
        {
          key: "code",
          label: "Mã Gift Card",
          stickyColumn: true,
          variant: "light",
          class: "align-middle",
        },
        {
          key: "status",
          label: "Trạng thái",
          class: "align-middle",
        },
        {
          key: "price",
          label: "Giá trị",
          class: "align-middle",
        },
        {
          key: "creator",
          label: "Người tạo",
          class: "align-middle",
        },
        {
          key: "customer",
          label: "Tên KH",
          class: "align-middle",
        },
        {
          key: "createdAt",
          label: "Thời gian tạo",
          class: "align-middle",
        },
        {
          key: "_id",
          label: "",
          class: "text-right",
        },
      ];
    },

    invoicefields() {
      return [
        {
          key: "time",
          label: "Thời gian",
          class: "align-middle",
          stickyColumn: true,
          variant: "light",
        },
        {
          label: "Lượt làm việc",
          key: "sessions",
          class: "align-middle",
        },
        {
          label: "Tên khách hàng",
          key: "customerName",
          class: "align-middle",
        },
        {
          label: "Trạng thái",
          key: "status",
          class: "align-middle",
        },
        {
          key: "total",
          label: "Tổng tiền",
          class: "text-right align-middle",
        },
        {
          key: "_id",
          label: "Thanh toán",
          class: "text-right align-middle",
        },
      ];
    },

    giftTotal() {
      return this.selectedGiftcard.reduce((total, item) => {
        total +=
          item.balance > this.invoiceData.total
            ? this.invoiceData.total
            : item.balance;
        return total;
      }, 0);
    },
  },
  watch: {
    cardValue(val) {
      let value = this.invoiceData.total - this.giftTotal - val;
      const total = this.invoiceData.total - this.giftTotal;
      if (val > total) this.$set(this, "cardValue", total, false);
      this.$set(this, "cashValue", value || 0, false);
    },

    invoiceData: {
      handler(val) {
        this.cardValue = val.total;
      },
      deep: true,
    },

    giftTotal(val) {
      let value = this.invoiceData.total - val;
      if (value < 0) {
        value = 0;
      }
      this.$set(this, "cardValue", value, false);
    },
  },
};
